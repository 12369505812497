
const initialState = {
  data: [],
  patientTotal: 0,
  reviewTotal: 0,
  params: {},
  patientAllData: [],
  reviewAllData: [],
  newData: [],
  selectedPatient: {},
  patientSign: '',
  loading: true
}

const patientReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'DELETE_REVIEW':
      const reviewAllData = state.reviewAllData.filter(r => r.id !== action.review_id)
      return {
        ...state,
        reviewAllData
      }
    case 'GET_PATIENT':
      return {
        ...state,
        patientDetail: action.patientDetail
      }
    case 'GET_REVIEW':
      return {
        ...state,
        selectedPatient: action.selectedPatient,
        is_edit: action.is_edit
      }
    case 'SET_SIGN':
      return {
        ...state,
        patientSign: action.patientSign
      }
    case 'GET_NEW_REVIEW_DATA':
      return {
        ...state,
        newData: action.data?.items || []
        // loading: action.loading
      }
    case 'REMOVE_PATIENT_REVIEW_MESSAGE':
      const newData1 = state.newData
      newData1.splice(action.index, 1)
      return {
        ...state,
        newData: newData1
      }
    case 'ADD_PATIENT_REVIEW_MESSAGE':
      const newData = state.newData
      newData.push(action.data)
      return {
        ...state,
        newData
        // loading: action.loading
      }

    case 'GET_REVIEW_DATA':
      return {
        ...state,
        reviewAllData: action.data?.items || [],
        total: action.data.total,
        last_page: action.data.last_page,
        loading: action.loading
      }

    case 'GET_ALL_PATIENT_DATA':
      return {
        ...state,
        patientAllData: action.data.items,
        total: action.data?.items.length,
        loading: action.loading
      }
    case 'GET_VISIT_DATA':
      return {
        ...state,
        patientVisitAllData: action.data.items,
        total: action.data.total || 0,
        last_page: action.data.last_page,
        discharged: action.data.discharged || 0,
        not_reviewed_count: action.data.not_reviewed_count || 0,
        loading: action.loading
      }
    case 'GET_PATIENT_DATA':
      return {
        ...state,
        patientAllData: action.data.items || [],
        total: action.data.total,
        last_page: action.data.last_page,
        loading: action.loading
      }

    case 'PROFILE_VERIFY_PRIMARY_INSURANCE':      
      if (action.primary_insurance) {
        state.patientDetail.profile.primary_insurance = action.primary_insurance
        state.patientDetail.profile.is_primary_insurance_validate = action.is_primary_insurance_validate
      }
      
      return {
        ...state,
        primaryInsuranceVerificationResponse: action.primaryInsuranceVerificationResponse,
        patientDetail: state.patientDetail
      }
    case 'REVIEW_VERIFY_PRIMARY_INSURANCE':      
      if (action.primary_insurance) {
        state.selectedPatient.profile.primary_insurance = action.primary_insurance
        state.selectedPatient.profile.is_primary_insurance_validate = action.is_primary_insurance_validate
      }
      
      return {
        ...state,
        primaryInsuranceVerificationResponse: action.primaryInsuranceVerificationResponse,
        selectedPatient: state.selectedPatient
      }    
    case 'LOGOUT':      
      return { ...initialState }
    default:
      return state
  }
}

export default patientReducer
